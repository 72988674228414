import { useContext, useState, useEffect } from 'react'
import firebase from 'firebase/app'
import 'firebase/database'

import { normalizeString } from '../utils'
import { ProductsContext, UserContext, /*UserContext*/ } from '../components/Context'
import { WarehouseContext } from '../components/Context/Warehouse'
//import { WAREHOUSES } from '../globals'
//import { WAREHOUSES_STOCK_FILTER } from '../calculateShipments'

const MINPRICE = 0

const MAXPRICE = 4000

const FETCHSTATES = {
  loading: 'loading',
  error: 'error',
  success: 'success',
}

const normalizeItemContainsWord = (item, word) => {
  const containsWord =
    normalizeString(item.sap_descripcion).includes(word) ||
    normalizeString(item.laboratorio).includes(word) ||
    normalizeString(item.sap_codigo).includes(word)

  // Ticket 1050623
  if (word.length < 8) {
    return containsWord
  }

  return containsWord || normalizeString(item.ean11).includes(word)
}

export const productsComparator = (a, b) => {
  if (a.estado === 'A' && b.estado !== 'A') return -1
  if (a.estado !== 'A' && b.estado === 'A') return 1
  if (a.estado === 'E' && b.estado === 'B') return -1
  if (a.estado === 'B' && b.estado === 'E') return 1
  if(a.sap_descripcion > b.sap_descripcion) return 1
  if(a.sap_descripcion < b.sap_descripcion) return -1
  return 0
}

export const useFilteredList = (
  items,
  search,
  sliceFrom = 0,
  sliceTo = 10,
  defaultReturn = [],
  minPrice = MINPRICE,
  maxPrice = MAXPRICE,
  isSearchMandatory = true
) => {
  const { displayStock } = useContext(ProductsContext)
  const [data, setData] = useState([])
  const [status, setStatus] = useState(FETCHSTATES.loading)
  const [lastAnalizedIndex, setLastAnalizedIndex] = useState(0)
  const [sourceData, setSourceData] = useState([])
  const { userRoute } = useContext(UserContext)
  const { getWarehousePriorityInterval } = useContext(WarehouseContext)
  //const userWarehouse = userRoute ? WAREHOUSES[userRoute.charAt(0)] : null
  const warehousesFilter = getWarehousePriorityInterval(userRoute);
  
  const productsRefs = []
  const chunkSize = sliceTo - sliceFrom - 1

  useEffect(() => {
    setData([])
    setLastAnalizedIndex(-1)
  }, [search, items, minPrice, maxPrice, displayStock])

  //Effect that will set the source data over the ones we will search results asynchronously
  //or not, pending if displayStock is active or inactive
  useEffect(() => {
    setStatus(FETCHSTATES.loading)
    if (!search.trim().length && isSearchMandatory) {
      setSourceData(defaultReturn)
    } else {
      let filteredItems = []
      const productsInsidePriceRange = items.filter((item) => {
        return item.pvf < minPrice || item.pvf > maxPrice ? false : true
      })

      if (search) {
        const searchWords = normalizeString(search).split(' ')
        filteredItems = productsInsidePriceRange.filter((item) =>
          searchWords.every((word) => normalizeItemContainsWord(item, word))
        )
      } else {
        filteredItems = productsInsidePriceRange
      }

      filteredItems.sort(productsComparator)
      setSourceData(filteredItems)
    }
  }, [items, search, minPrice, maxPrice, displayStock])

  //in charge of loading asynchronously the inStock products if
  //needed
  useEffect(() => {
    const getNextItemInStock = async () => {
      const loopLowerLimit = lastAnalizedIndex + 1
      const loopUpperLimit = sourceData.length
      for (let i = loopLowerLimit; i < loopUpperLimit; i++) {
        const item = sourceData[i]
        const isInStock = await isItemInStock(item)

        if (isInStock) {
          setLastAnalizedIndex(i)
          //IMPORTANT to avoid asynchronous overrides, instead of using setData([...prevData, item])
          //use setData(prevData => [...prevData, item]), it's a safer way based on react docs to prevent
          //asynchronous kind of bugs
          setData((prevData) => [...prevData, item])
          break
        }
      }
    }

    if (!displayStock) {
      const allItems = sourceData.slice(sliceFrom, sliceTo)

      setData(allItems)
      setStatus(FETCHSTATES.success)
    } else if (data.length < chunkSize && lastAnalizedIndex < sourceData.length) {
      getNextItemInStock()
    } else {
      //When all data is retrived or there is no more that to look for,
      //then we return success status
      setStatus(FETCHSTATES.success)
    }

    //IMPORTANT! Remove all product connections to firebase, if not
    // it will thrown an error of type 'cannot execute setState on an unmounted
    // component', this is because we must stop all promises
    return () => productsRefs.forEach((ref) => ref.off())
  }, [lastAnalizedIndex, sourceData, chunkSize])

  const getItemRef = (item) =>
    firebase.database().ref(`products/stocks/${parseInt(item.sap_codigo, 10)}`)

  const isItemInStock = (item) => {
    const itemRef = getItemRef(item)
    productsRefs.push(itemRef)
    
    // Filter the item, taking in consideration the warehouses that are available for the user.
    let itemFilteredByWarehosue = itemRef
    .once('value')
    .then((snapshot) => Object.keys(snapshot.val()).filter(key => warehousesFilter.includes(key)).reduce((obj,key) => {
      obj[key] = snapshot.val()[key];
      return obj;
    },{}) );

    // Indicates if there is some stock of an item, taking in consideration the available warehouses.
    function currentStock(item){
      return item ? item.then(val =>  val ? Object.values(val) : false).then( val => val ?  val.some((stock) => parseInt(stock) > 0) : false) : false;
    }
      
    return currentStock(itemFilteredByWarehosue);
    
    
    // Old filter, not taking in consideration warehouses
    /*return itemRef
      .once('value')
      .then((snapshot) =>
        
        (snapshot.val() ? Object.values(snapshot.val()).some((stock) => parseInt(stock) > 0) : false)
      )*/
  }
  
  return [data, status]
}
